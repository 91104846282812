import React from "react";
import Modal from "react-modal";
import ReactPlayer from 'react-player';

Modal.setAppElement("#root");

export default function VideoModal({ isOpen, onClose, videoSrc }) {
  const customStyles = {
    content: {
      display: 'flex', // Use flexbox for centering
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center', // Center content horizontally and vertically
      width: '80%', // Slightly reduced width for better margin on small screens
      height: '80%', // Slightly reduced height
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)', // Centering the modal
      borderRadius: '10px',
      backgroundColor: 'black',
      padding: '10px',
      overflow: 'hidden', // Prevents overflow issues
    },
    overlay: {
      zIndex: 9998,
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Slightly darker overlay for better focus on video
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <button onClick={onClose} style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '2rem',
        outline: 'none',
        textShadow: '1px 1px 1px #000',
        zIndex: 10000
      }}>Close &times;</button>
      <ReactPlayer
        url={videoSrc}
        playing={isOpen}
        controls={true}
        width='100%'
        height='100%' // Ensuring video fills the flex container
        style={{
          alignSelf: 'center', // Center the player within flex container
        }}
      />
      <div style={{
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        color: '#fff',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textShadow: '1px 1px 1px #000',
        zIndex: 9999
      }}>
        MeanC
      </div>
    </Modal>
  );
}
