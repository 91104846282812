import React ,{ useState} from 'react';
import './LandingPage.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye,faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
  const { t } = useTranslation();
  const [userType, setUserType] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  
  return (
    <>
      <section
          className="cover-secs-landing"
          style={{
            position: 'relative',
            width: '100%',
            height: '500px',
            // backgroundImage: 'url("/images/image-fanclub.png")',
            backgroundImage: 'url("/images/meanc-hero3.png")', // the hero img
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: '#ffffff',
            textAlign: 'center',
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden', // Prevents blurring outside section
          }}
        >
          <header className="landing-header">
           <h1 style={{ 
              fontSize: 'clamp(2rem, 4vw, 4rem)', /* Responsive font size */
              fontWeight: 'bold', 
              margin: '0.5rem 0', 
              lineHeight: '1.1'
            }}>
              {t('abouter.header.title')} 
              <span style={{ color: 'red', display: 'block'}}>SPORTS</span>
            </h1>
            <p style={{ fontSize: '1.5rem', maxWidth: '600px', margin: '0 auto' }}>
              {t('abouter.header.description')}
            </p>
            <button
              style={{
                marginTop: '20px',
                padding: '15px 20px',
                fontSize: '1rem',
                color: '#fff',
                background: 'linear-gradient(90deg, #007BFF, #00A1FF)',
                border: '2px solid #fff',
                borderRadius: '25px',
                cursor: 'pointer',
                boxShadow: '0 4px 15px rgba(0, 123, 255, 0.75)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.boxShadow = '0 6px 20px rgba(0, 123, 255, 0.9)';
                e.target.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.target.style.boxShadow = '0 4px 15px rgba(0, 123, 255, 0.75)';
                e.target.style.transform = 'scale(1)';
              }}
              onClick={() => navigate(`/login`)}
            >
              Get Started
            </button>
          </header>
      </section>
      <section >
        <div className='landing-detail-profile'>
          <h1>Select Your User Type</h1>
            <div className='card-container'>
              <div className='card' onClick={() => navigate(`/login`)}>
                <img src="/images/recruiter.png" alt="Recruiter" className="card-image"/>
                <h2>{(t('recruiter'))}</h2>
                <p>{t('recruiterdescription')}</p>
              </div>
              <div className='card' onClick={() => navigate(`/login`)}>
                <img src="/images/player.png" alt="Player" className="card-image"/>
                <h2>{(t('athlete'))}</h2>
                <p>{t('athletedescription')}</p>
              </div>
              <div className='card' onClick={() => navigate(`/login`)}>
                <img src="/images/fans-meanc.png" alt="Simple User" className="card-image"/>
                <h2>{(t('fan'))}</h2>
                <p>{t('fandescription')}</p>
              </div>
            </div>
            <button className='button-cta' onClick={() => navigate(`/login`)}> Click to sign up</button>
        </div>
      </section>
      <section className="landing-vision">
          {/* Left Column: Image Placeholder */}
          <div className='lv-image'>
            <img 
              src="/images/sp20.png" 
              alt="Vision" 
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
            />
          </div>

          {/* Right Column: Title and Description */}
          <div className='lv-text'>
            <h2
              style={{
                fontSize: '3.5rem',
                marginBottom: '1rem',
                textAlign: 'center', // Center horizontally
              }}
            >
              <FontAwesomeIcon
                icon={faBullseye}
                style={{ fontSize: '3.5rem', color: '#555', marginRight: '0.5rem' }}
              />
              {t('abouter.mission.title')}
            </h2>
            <p>
              {t('abouter.mission.description')}
            </p>
            <span  className='text-shadow-custom'
              style={{ color: 'red', display: 'block', fontSize:'55px', cursor:'pointer'}} 
              onClick={() => navigate(`/login`)}> 
              Join Now
            </span>
          </div>
      </section>
      <section className="about-features">
        <h2>{t('abouter.features.title')}</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>{t('abouter.features.global_reach.title')}</h3>
            <img src="images/global_sports_connections.jpg" alt={t('abouter.features.global_reach.title')} className="feature-image" />
            <p>{t('abouter.features.global_reach.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.multilingual_support.title')}</h3>
            <img src="images/Multilingual_Support.jpg" alt={t('abouter.features.multilingual_support.title')} className="feature-image" />
            <p>{t('abouter.features.multilingual_support.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.video_review.title')}</h3>
            <img src="images/Manual_Video_Review.jpg" alt={t('abouter.features.video_review.title')} className="feature-image" />
            <p>{t('abouter.features.video_review.description')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('abouter.features.ai_powered.title')}</h3>
            <img src="images/AI_Powered_Recommendations.jpg" alt={t('abouter.features.ai_powered.title')} className="feature-image" />
            <p>{t('abouter.features.ai_powered.description')}</p>
          </div>
        </div>
      </section>

      <section className="landing-vision">
          <div className='lv-image'>
            <img 
              src="/images/vision.jpg" 
              alt="Vision" 
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
            />
          </div>
          <div className='lv-text'>
          <h2
              style={{
                fontSize: '3.5rem',
                marginBottom: '1rem',
                textAlign: 'center', // Center horizontally
              }}
            >
              {/* <FontAwesomeIcon
                icon={faGlobe}
                style={{ fontSize: '3.5rem', color: '#555', marginRight: '0.5rem' }}
              />  */}
              <img src="images/sp122.png" alt="logo-cm" 
              style={{ width: '100%', height: 'auto', objectFit: 'cover', background:'white' }} 
              />{t('abouter.vision.title')}</h2>
            <p>{t('abouter.vision.description')}</p>
          </div>
      </section>

      <section className="landing-join-us">
        <h2 className='text-shadow-custom' onClick={() => navigate(`/login`)}>{t('abouter.join_us.title')}</h2>
        <p>{t('abouter.join_us.description')}</p>
        <div className="join-us-image-placeholder">
          <img src="images/bg1.jpg" alt={t('abouter.join_us.title')} />
        </div>
      </section>

      {/* <Link to="/" className="floating-signin-btn">
        <FontAwesomeIcon icon={faSignIn} /> {t('abouter.signin_button')}
      </Link> */}
    </>
  );
};

export default LandingPage;
