import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <div className="footy-sec mn no-margin">
                <div className="container">
                    <ul>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/" >{t('help_center')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/" >{t('career')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/" >{t('forum')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/Terms" >{t('termsAndConditions')}</Link>
                        </li>
                        {/* <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/About" >{t('about')}</Link>
                        </li> */}
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/Privacy" >{t('privacy_policy')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/Community" >{t('community_guidelines')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/Cookies" >{t('cookies_policy')}</Link>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <Link style={{ color: 'black' }} to="/Copyright" >{t('copyright_policy')}</Link>
                        </li>
                    </ul>
                    <p style={{ color: 'black' }}>
                        <a href="https://www.linkedin.com/in/moussadiallo14/" target="_blank" rel="noreferrer">
                            <img src="images/copy-icon2.png" alt="" />{t('copyright_meanc')} Ohio,USA
                        </a>
                        </p>
                        <img className="fl-rgt" src="images/logo.ico" alt="" />
                </div>
            </div>
        </>
    );
}
