import React, { useState } from 'react';
import { Navbar, Container, Nav, Image, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header5() {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false); // Close dropdown on language change
  };

  return (
    <>
      <Navbar
        expand={false}
        bg="light"
        className="custom-navbar align-items-left fixed-top"
        style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Container>
          <Navbar.Brand style={{ marginLeft: '10px' }}>
            <Link to="/">
              <Image
                src="https://app.meanc.org/images/logo12.png"
                alt="My Logo"
                style={{ width: '70px', height: '40px' }}
              />
            </Link>
          </Navbar.Brand>

          {/* Language Dropdown */}
          <Dropdown
            className="language-dropdown"
            align="end"
            onToggle={(isOpen) => setDropdownOpen(isOpen)}
            show={dropdownOpen}
            popperConfig={{
              modifiers: [
                { name: 'preventOverflow', options: { boundary: 'viewport' } },
                { name: 'offset', options: { offset: [0, 10] } }, // Ensures spacing
              ],
            }}
          >
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <FontAwesomeIcon icon={faGlobe} /> Language ({i18n.language})
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('fr')}>Français</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('es')}>Español</Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>

      {/* Overlay to close dropdown */}
      {dropdownOpen && (
        <div
          className="overlay"
          onClick={() => setDropdownOpen(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
          }}
        ></div>
      )}
    </>
  );
}
