import React from "react";
import Header5 from "../PageLayout/header5";
import Footer from "../PageLayout/Footer";
import LandingPage from "./LandingPage";

export default function LandingHome(){
    return <>
        <Header5 /> 
         <LandingPage />
        <Footer />  
    </>
}